import React , { Component } from "react";

class HistoryVersion extends Component{
    render(){
        return(
            <div id="history" className={`download-area ${this.props.horizontal}`}>
                <div className="bg-overlay"/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--55 pt--50">  
                                <h2 className="text-white">ИСТОРИЯ ИЗМЕНЕНИЯ <span className="theme-color">ВЕРСИЙ</span></h2>
                                <img src={require('../assets/images/app/title-icon-4.webp')} alt="Cosmos-diagram Logo"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.1.10.0</p>
                                <p><a href="https://www.youtube.com/watch?v=4BG9ByH688U" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение</a></p>
                                <a href="/downloads/cosmos-diagram-v1.1.10.0-setup.zip" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.1.9.0</p>
                                <p><a href="https://www.youtube.com/watch?v=HK1Cqobw58w" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение</a></p>
                                <a href="/downloads/cosmos-diagram-v1.1.9.0-setup.zip" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.1.8.0</p>
                                <p><a href="https://www.youtube.com/watch?v=Xw4DP-9wJrk" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение</a></p>
                                <a href="/downloads/cosmos-diagram-v1.1.8.0-setup.zip" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.0.6.0</p>
                                <p><a href="https://www.youtube.com/watch?v=I4NMAXt-Rwk" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение</a></p>
                                <a href="/downloads/cosmos-diagram-v1.0.6.0-setup.zip" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.0.5.0</p>
                                <p><a href="https://www.youtube.com/watch?v=rhIweCtDb3s" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение</a></p>
                                <a href="/downloads/cosmos-diagram-v1.0.5.0-setup.zip" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.0.4.0</p>
                                <p><a href="https://www.youtube.com/watch?v=IJJXErFR3_0" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение</a></p>
                                <a href="/downloads/cosmos-diagram-v1.0.4.0-setup.exe" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.0.3.0</p>
                                <p><a href="https://www.youtube.com/watch?v=Rt5tIIu7Gl4" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение</a></p>
                                <a href="/downloads/canvas-diagram-v1.0.3.0-setup.exe" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.0.2.0</p>
                                <p><a href="https://www.youtube.com/watch?v=M65gUuAKc64" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение</a></p>
                                <a href="/downloads/canvas-diagram-v1.0.2.0-setup.exe" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.0.1.0</p>
                                <p><a href="https://www.youtube.com/watch?v=KJcj6x8l0Rs" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение</a></p>
                                <a href="/downloads/canvas-diagram-v1.0.1.0-setup.exe" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.0.0.0</p>
                                <p><a href="https://www.youtube.com/watch?v=IzVJMCyL7hQ" target="_blank" className={"underline theme-color"}>Основное Видеообучение</a></p>
                                <a href="/downloads/canvas-diagram-v1.0.0.0-setup.exe" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HistoryVersion;







